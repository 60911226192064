import React, { useMemo, useState } from 'react';
import { useWatch } from 'react-hook-form';

import courseDeliveryMethods from 'common-lib/constants/courseDeliveryMethods.json';

import { useTranslate } from '@acadeum/translate';
import { ContentSection, FormField } from '@acadeum/ui';

import { Mode } from '../helpers';

export interface CreateScheduleSectionValues {
  deliveryMethod?: string;
  location?: string;
  // sections: {
  //   schedule: string | null;
  // }[];
}

export interface EditScheduleSectionValues {
  deliveryMethod?: string;
  location?: string;
}

interface ScheduleSectionProps {
  mode: Mode;
}

export function ScheduleSection({ mode }: ScheduleSectionProps) {
  const t = useTranslate('shared-admin-ui.CourseForm');
  const onDemand = useWatch({ name: 'onDemand' });

  const [deliveryMethod, setDeliveryMethod] = useState('');

  const onChooseDeliveryMethod = (event) => {
    const method = event.target.value;
    setDeliveryMethod(method);
  };

  const deliveryMethodOptions = useMemo(() => {
    return courseDeliveryMethods.map(method => ({
      value: method, label: method
    }));
  }, []);

  return (
    <ContentSection title={t('schedule.title')}>
      <FormField
        type="select"
        name="deliveryMethod"
        options={deliveryMethodOptions}
        onChange={onChooseDeliveryMethod}
        label={t('schedule.deliveryMethod.label')}
        placeholder={t('schedule.deliveryMethod.placeholder')}
      />
      {!onDemand && mode === Mode.CREATE && (
        <FormField
          noMargin={deliveryMethod === 'Online'}
          name="sections.0.schedule"
          label={t('schedule.schedule.label')}
          helpText={t('schedule.deliveryMethod.helpText')}
          placeholder={t('schedule.schedule.placeholder')}
        />
      )}
      {deliveryMethod !== 'Online' && (
        <FormField
          noMargin
          name="location"
          label={t('schedule.location.label')}
          helpText={t('schedule.location.helpText')}
          placeholder={t('schedule.location.placeholder')}
        />
      )}
    </ContentSection>
  );
}
