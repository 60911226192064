import type { Course, Institution, Section, Session } from '@acadeum/types';

import type { UseJob } from './api';

interface UploadCourseRow extends Pick<Course,
  'code' |
  'title' |
  'description' |
  'level' |
  'hours' |
  'requiredTexts' |
  'zeroTextbookCost' |
  'courseMaterialISBNs' |
  'courseMaterialTitles' |
  'deliveryMethod' |
  'location' |
  'additionalCosts' |
  'certificates' |
  'notes'> {
  category: Course['categories']; // Deprecated field name
  pathToSyllabus: Course['syllabusUrl']; // Deprecated field name
  prerequisiteText: Course['prerequisitesText']; // Deprecated field name
  resources: Array<{
    name: string;
    url: string;
    type: 'FACULTY_CREDENTIAL' | 'LEARNING_ASSESSMENT';
  }>;
  session: Pick<Session, 'startDate' | 'endDate' | 'lastAddDate' | 'lastDropDate' | 'internalTerm' | 'internalName' | 'cost'> & {
    section: Pick<Section, 'availableSeats' | 'schedule' | 'instructorFirstName' | 'instructorMiddleName' | 'instructorLastName'> & {
      isActive?: boolean;
      number: string; // Its deprecated section field. Now it's name
    }
  };
}

export interface UploadCoursesInput {
  rows: UploadCourseRow[];
  courseMode?: 'create' | 'read';
  dryRun?: boolean;
}

export interface UploadCoursesOutput {
  courseIds?: Course['id'][];
  courseSectionIds?: Section['id'][];
  results: ('CREATED' | 'UPDATED' | 'UNCHANGED' | 'DUPLICATE')[];
  count: {
    courses: number;
    sections: number;
  };
}

export const getUseUploadCourses = (useJob: UseJob) => () => {
  const job = useJob();
  return (parameters: UploadCoursesInput) => job<UploadCoursesOutput>('courseUpload', parameters);
};

interface BulkCreateOrUpdateOutputItem {
  courseId: Course['id'];
  courseSectionId: Section['id'];
  status: 'CREATED' | 'UPDATED' | 'UNCHANGED' | 'DUPLICATE';
}

export type BulkCreateOrUpdateOutput = BulkCreateOrUpdateOutputItem[]

export interface BulkCreateOrUpdateInput {
  institutionId?: Institution['id'];
  courseSections: UploadCoursesInput[];
  courseMode?: 'create' | 'read';
  dryRun?: boolean;
}

export const getUseBulkUpsertCoursesJob = (useJob: UseJob) => () => {
  const job = useJob();
  return (parameters: BulkCreateOrUpdateInput) => job<BulkCreateOrUpdateOutput>('courses-bulkCreateOrUpdate', parameters);
};

export type UseBulkUpsertCoursesJob = ReturnType<typeof getUseBulkUpsertCoursesJob>
