import React from 'react';
import { useWatch } from 'react-hook-form';

import { useTranslate } from '@acadeum/translate';
import { ContentSection, FormField, FormRow } from '@acadeum/ui';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface CreateEnrollmentSectionValues {
  // sections: {
  //   cost: number;
  //   lastAddDate: Date;
  //   lastDropDate: Date;
  // }[];
}

export function EnrollmentSection() {
  const t = useTranslate('shared-admin-ui.CourseForm');
  const onDemand = useWatch({ name: 'onDemand' });

  return (
    <ContentSection title={t('enrollment.title')}>
      <FormField
        required
        noMargin={onDemand}
        name="sections.0.cost"
        currency="USD"
        type="currency"
        label={t('enrollment.cost.label')}
        placeholder={t('enrollment.cost.placeholder')}
      />
      {!onDemand && (
        <FormRow mb="none">
          <FormField
            required
            name="sections.0.lastAddDate"
            type="date"
            label={t('enrollment.lastAddDate.label')}
          />
          <FormField
            required
            noMargin
            name="sections.0.lastDropDate"
            type="date"
            label={t('enrollment.lastDropDate.label')}
          />
        </FormRow>
      )}
    </ContentSection>
  );
}
