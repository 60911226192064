import type { FC } from 'react';
import React, { createContext, memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import omit from 'lodash/omit';
import type { Resolver} from 'react-hook-form';
import { useFieldArray, useFormContext, useFormState, useWatch } from 'react-hook-form';
import type { ColumnDef } from '@tanstack/react-table';

import { getAuthSelector } from '@acadeum/auth';
import { isAcadeumAdministrator, isJobError } from '@acadeum/helpers';
import type { FileUploadRef, FormRef, OnSubmit } from '@acadeum/ui';
import {
  Alert,
  Checkbox,
  DownloadButton,
  FileUpload,
  Form,
  Grid,
  InstitutionAutocomplete,
  StickyFormFooter,
  Table,
  Tooltip,
  Tag
} from '@acadeum/ui';
import { useTranslate } from '@acadeum/translate';
import { getIgnoredColumns, parseFile, type ParseFileReturn } from '../DataUploadPage';

import styles from './DataUploadPage2.module.scss';

interface DataUploadPage2ContextProps {
  shouldValidate?: boolean;
}

const DataUploadPage2Context = createContext<DataUploadPage2ContextProps>({});

interface StatusCellProps {
  t: ReturnType<typeof useTranslate>;
  status: string;
}

const StatusCell = memo<StatusCellProps>(({
  t,
  status
}) => {
  return (
    <Tooltip
      placement="left"
      content={t(`statusTooltip.${status.toLowerCase()}`)}
    >
      {status === 'valid' ? (
        <Tag
          className={styles.DataUploadPage__statusTag}
          children="Valid"
          variant="green"
        />
      ) : (
        <Tag
          className={styles.DataUploadPage__statusTag}
          variant={status}
        />
      )}
    </Tooltip>
  );
});

function RowIndexCell({ row }) {
  const { register } = useFormContext();
  return (
    <div className="DataUploadPage__rowIndexCell">
      {row.original.rowIndex + 1}
      <input
        type="hidden"
        {...register(`rows.${row.index}.rowIndex`, {
          valueAsNumber: true
        })}
      />
    </div>
  );
}

function parseHeaders({
  headerRow,
  getColumnSchema = column => schema[column],
  schema
}) {
  return headerRow.map((columnName) => {
    const schema = getColumnSchema(columnName);
    return {
      header: columnName,
      accessorKey: schema.prop,
      cell: schema.field
    };
  });
}

type ExpectedResult = { status: string | 'CREATED' | 'UPDATED' | 'UNCHANGED' | 'DUPLICATE' }[];

interface FormValues {
  rows: object[];
  // Provided only when user is acadeum admin.
  institutionId?: number;
}

export interface DataUploadPageCache<T = object[]> {
  fileName?: string;
  hasError?: boolean;
  columns: ColumnDef<unknown>[];
  ignoredColumns?: string[];
  tableData?: ParseFileReturn['tableData'];
  rows: ParseFileReturn<T>['parsedData']['rows'];
  expectedResult?: ExpectedResult;
  institutionId?: number;
  shouldValidate?: boolean;
}

type CacheOptions = [(DataUploadPageCache | undefined), React.Dispatch<React.SetStateAction<DataUploadPageCache | undefined>>];

export interface DataUploadPage2Props {
  className?: string;
  cacheOptions?: CacheOptions;
  schema: NonNullable<unknown>;
  getColumnSchema?: (columnName: string) => object;
  templateUrl: string;
  validate: (values: FormValues) => Promise<ExpectedResult>;
  onUpload: (values: FormValues) => Promise<void>;
  onBack?: () => void;
  submitText?: string;
  resolver?: Resolver;
}

export const DataUploadPage2: FC<DataUploadPage2Props> = ({
  className,
  getColumnSchema,
  schema,
  validate,
  onUpload,
  templateUrl,
  cacheOptions,
  onBack,
  submitText,
  resolver
}) => {
  const t = useTranslate('shared-admin-ui.DataUploadPage2');

  const institutionFormRef = useRef<FormRef>(null);
  const fileUploadRef = useRef<FileUploadRef>(null);

  const user = useSelector(getAuthSelector('user'));

  const [fileUploadError, setFileUploadError] = useState<string>();

  const cacheState = useState<DataUploadPageCache>();
  const [cache, updateCache] = cacheOptions || cacheState;

  const resetState = () => {
    updateCache(undefined);
    setFileUploadError(undefined);
  };

  const [institutionId, setInstitutionId] = useState<number | undefined>(cache?.institutionId);
  const onSubmitInstitutionForm = ({ institutionId }) => {
    resetState();
    setInstitutionId(institutionId);
  };

  const fileName = cache?.fileName;
  const tableData = cache?.tableData;
  const columns = cache?.columns;
  const rows = cache?.rows;
  const ignoredColumns = cache?.ignoredColumns;
  const shouldValidate = cache?.shouldValidate;

  const onFileChosen = async (file) => {
    resetState();

    const parseFileReturn = await parseFile(file, { schema });

    if (parseFileReturn.parsedData.rows.length === 0) {
      return setFileUploadError(t('noData'));
    }

    const { tableData, parsedData, headerRow: _headerRow } = parseFileReturn;

    // When a column has an empty header, the corresponding element
    // in `columns` array is gonna be `null`.
    // Passing `null` as a child of `<List.Item/>` will output a React warning
    // so it replaces `null` with "<No Header>" string.
    //
    // To work around that, assign some placeholder value to `null` (empty) column titles.
    //
    // https://github.com/Acadeum/Tickets/issues/1560
    const headerRow = _headerRow.map((header, i) => header || `<#${i + 1}>`);

    const ignoredColumns = getIgnoredColumns(headerRow, schema);

    updateCache({
      tableData,
      rows: parsedData.rows.map((row, index) => ({
        ...row,
        rowIndex: index
      })),
      ignoredColumns,
      institutionId,
      fileName: file.name,
      shouldValidate: true,
      columns: parseHeaders({
        headerRow: headerRow.filter(header => !ignoredColumns.includes(header)),
        getColumnSchema: getColumnSchema,
        schema
      })
    });
  };

  const onValidate: OnSubmit<FormValues> = async ({ rows }, { setValue }) => {
    setFileUploadError(undefined);
    try {
      const expectedResult = await validate({
        institutionId,
        rows: rows.map(row => omit(row, 'id', 'status', 'rowIndex'))
      });
      const updatedRows = rows.map((row, index) => ({
        ...row,
        status: expectedResult?.[index]?.status
      }));
      setValue('rows', updatedRows);
      updateCache((cache) => {
        if (!cache) return cache;
        return {
          ...cache,
          shouldValidate: false,
          rows: updatedRows
        };
      });
    } catch (error: unknown) {
      if (isJobError(error)) {
        setFileUploadError(error.data.message || 'Failed to validate the data');
      } else {
        throw error;
      }
    }
  };

  const onSubmit: OnSubmit<FormValues> = async ({ rows }) => {
    await onUpload({
      institutionId,
      rows: rows.map(row => omit(row, 'id', 'status', 'rowIndex'))
    });
  };

  const onFormSubmit: OnSubmit<FormValues> = async (values, options) => {
    return (shouldValidate ? onValidate : onSubmit)(values, options);
  };

  const onFormChange = useCallback(() => {
    setFileUploadError(undefined);
    // This `if` is added to prevent re-rendering of all page every time the user changes the form.
    // Because `cache` often can stored on top level of the page, and it's create so many re-renders.
    // And this re-renders are very expensive.
    if (!cache?.shouldValidate) {
      updateCache(prev => {
        if (!prev) {
          return prev;
        }
        return {
          ...prev,
          shouldValidate: true
        };
      });
    }
  }, [
    setFileUploadError,
    updateCache,
    cache?.shouldValidate
  ]);

  return (
    <DataUploadPage2Context.Provider value={{ shouldValidate }}>
      <Grid
        container
        direction="column"
        className={classNames(styles.root, className)}
        rowSpacing={3}
      >
        <Grid size={{ xs: 12, md: 4 }}>
          {isAcadeumAdministrator(user) && (
            <Form
              ref={institutionFormRef}
              onSubmit={onSubmitInstitutionForm}
              defaultValues={{ institutionId }}
            >
              <InstitutionAutocomplete
                noMargin
                required
                superAdmin
                type="institution"
                className={styles.InstitutionAutocomplete}
                onChange={() => institutionFormRef.current?.submit()}
              />
            </Form>
          )}
        </Grid>

        {(isAcadeumAdministrator(user) ? institutionId : true) && (
          <>
            <Grid size={12}>
              <DownloadButton url={templateUrl}>
                {t('downloadTemplate')}
              </DownloadButton>
            </Grid>

            <Grid size={12}>
              <FileUpload
                ref={fileUploadRef}
                children={t('fileUploadText')}
                fileName={fileName}
                accept={[
                  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                  'text/csv'
                ]}
                uploadButtonTitle={t('uploadButtonTitle')}
                removeButtonTitle={t('removeButtonTitle')}
                onChange={onFileChosen}
                onReset={resetState}
                alertProps={fileUploadError ? {
                  variant: 'error',
                  children: fileUploadError
                } : undefined}
              />
            </Grid>
          </>
        )}

        {ignoredColumns && ignoredColumns.length > 0 && (
          <Grid size={12}>
            <Alert variant="warn">
              {t('ignoredColumns', { columns: ignoredColumns.join(', ') })}
            </Alert>
          </Grid>
        )}

        {tableData && columns && (
          <Grid size={12}>
            <Form
              mode="onBlur"
              resolver={resolver}
              onSubmit={onFormSubmit}
              defaultValues={{ rows }}
              shouldUnregister={false}
            >
              <OnFormChange onChange={onFormChange}/>
              <TriggerFormInitially/>
              <InfoAlert/>
              <FormHasErrorBanner/>
              <TableWithFormContext columns={columns}/>
              <br/>
              <FormFooterWithFormContext
                onBack={onBack}
                submitText={submitText}
                resetState={resetState}
              />
            </Form>
          </Grid>
        )}
      </Grid>
    </DataUploadPage2Context.Provider>
  );
};

/**
 * Used to trigger the form change when the values change.
 * This used instead of `onChange` prop of `<Form/>` because field.onChange from useController
 * doesn't trigger the form change when the values change.
 * */
function OnFormChange({ onChange }) {
  const { watch } = useFormContext();

  useEffect(() => {
    const subscription = watch((_, { name }) => {
      if (name?.startsWith('rows.')) {
        onChange();
      }
    });
    return () => subscription.unsubscribe();
  }, []);

  return null;
}

const TableWithFormContext = ({
  columns: propsColumns
}) => {
  const t = useTranslate('shared-admin-ui.DataUploadPage2');
  const {
    trigger,
    getValues,
    formState: { errors }
  } = useFormContext();
  const { shouldValidate } = useContext(DataUploadPage2Context);

  const columns = useMemo(() => {
    return [
      {
        header: '#',
        accessorKey: 'rowIndex',
        cell: RowIndexCell
      },
      ...propsColumns,
      {
        header: t('status'),
        accessorKey: 'status',
        cell: ({ row }) => {
          const status = getValues(`rows.${row.index}.status`);
          const hasError = Boolean(errors?.rows?.[row.index]);
          const cellStatus = (shouldValidate || !status) ? (hasError ? 'error' : 'valid') : status;
          return (
            <StatusCell
              t={t}
              status={cellStatus}
            />
          );
        }
      }
    ];
  }, [
    t,
    errors,
    propsColumns,
    getValues,
    shouldValidate
  ]);
  const indexesWithErrors = errors?.rows ? Object.keys(errors?.rows).map(Number) : [];
  const hasErrors = indexesWithErrors.length > 0;
  const { fields, replace } = useFieldArray<any>({ name: 'rows' });

  const [showErrorOnly, setShowErrorOnly] = useState(false);
  const [allFields, setAllFields] = useState(fields);

  const toggleShowErrorOnly = () => {
    setShowErrorOnly((prev) => {
      const newShowErrorOnly = !prev;

      if (newShowErrorOnly) {
        setAllFields(fields);
        const errorFields = indexesWithErrors.map((index) => fields[index]);
        replace(errorFields);
      } else {
        const rows = getValues('rows');
        replace(allFields.map((field) => {
          // @ts-expect-error field.rowIndex is a number and it's a key in rows
          const fieldWithError = rows.find((f) => f.rowIndex === field.rowIndex);
          if (fieldWithError) {
            return {
              ...field,
              ...fieldWithError
            };
          }
          return field;
        }));
      }

      // Trigger the `rows` field to update the form state.
      void trigger('rows');

      return newShowErrorOnly;
    });
  };

  const renderTopLeftToolbarCustomActions = () => {
    if (!(hasErrors || showErrorOnly)) {
      return null;
    }

    return (
      <Checkbox
        type="switch"
        label={t('showErrorOnly')}
        checked={showErrorOnly}
        onChange={toggleShowErrorOnly}
      />
    );
  };

  const meta = useMemo(() => ({
    getRowHasError: ({ row }) => Boolean(errors?.rows?.[row.index])
  }), [errors]);

  return (
    <>
      <Table
        data={fields}
        columns={columns}
        columnPinningRight={['status']}
        meta={meta}
        renderTopLeftToolbarCustomActions={renderTopLeftToolbarCustomActions}
      />
    </>
  );
};

const FormHasErrorBanner = memo(() => {
  const { errors } = useFormState();
  console.log('* errors', errors);
  const rows = useWatch({ name: 'rows' });
  const rowIndexes = Object.keys(errors?.rows ?? []).map(index => rows[index].rowIndex);

  if (rowIndexes.length === 0) {
    return null;
  }

  return (
    <Alert variant="error" style={{ marginBottom: '1rem' }}>
      The data will be uploaded, but there are errors in the table below. Please fix them. Errors were found in
      the following row(s): {rowIndexes.map(index => Number(index) + 1).join(', ')}.
    </Alert>
  );
});

function TriggerFormInitially() {
  const { trigger } = useFormContext();
  useEffect(() => {
    void trigger();
  }, [trigger]);
  return null;
}

function FormFooterWithFormContext({
  onBack,
  submitText,
  resetState
}) {
  const { shouldValidate } = useContext(DataUploadPage2Context);
  return (
    <StickyFormFooter
      onBackProps={onBack ? { onClick: onBack } : undefined}
      onCancelProps={{
        onClick: resetState
      }}
      submitProps={{
        children: shouldValidate ? 'Validate' : submitText || 'Submit'
      }}
    />
  );
}

function InfoAlert() {
  const { shouldValidate } = useContext(DataUploadPage2Context);

  const rows = useWatch({ name: 'rows' });
  const updatedRecordsCount = rows?.filter(row => row?.status === 'UPDATED').length;
  const unchangedRecordsCount = rows?.filter(row => row?.status === 'UNCHANGED').length;
  const hasUpdatedRecords = updatedRecordsCount > 0;
  const hasUnchangedRecords = unchangedRecordsCount > 0;

  if ((!hasUpdatedRecords && !hasUnchangedRecords) || shouldValidate) {
    return null;
  }

  return (
    <>
      <Alert variant="info">
        <p>Data will be uploaded</p>
        {hasUpdatedRecords && (
          <p>
            {updatedRecordsCount} record(s) from this file were previously uploaded. They have now been updated and
            marked with the “Updated” status.
          </p>
        )}
        {hasUnchangedRecords && (
          <p>
            {unchangedRecordsCount} record(s) from this file were previously uploaded but were not updated this time.
            They are marked with the “Unchanged” status.
          </p>
        )}
      </Alert>
      <br/>
    </>
  );
}
