import type { FC } from 'react';
import React, { useMemo, useState } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import get from 'lodash/get';
import { useSelector } from 'react-redux';

import { FEE_TYPE_OPTIONS, INVOICE_STATUS_OPTIONS, optionsWithAllOption } from '@acadeum/selection-options';
import { getAuthSelector } from '@acadeum/auth';
import { useTranslate } from '@acadeum/translate';
import type { TableExportDataColumns, TabsNavigationProps } from '@acadeum/ui';
import { Actions, Blank, DataBlock, Filters, FormatDate, Link, Page, Table, TabsNavigation, Tag } from '@acadeum/ui';
import type {
  FetchInvoicesListItem,
  UseFetchInvoiceQuery,
  UseFetchInvoicesQuery,
  UseLazyFetchInvoicesQuery
} from '@acadeum/api';
import { useQueryWithPagination } from '@acadeum/hooks';

import { useLocation } from '../../providers/useLocation';
import { useApp } from '../../providers/useApp';
import { formatCurrency } from '../../helpers/format';
import { useSettingsRoutes } from '../../hooks/useSettingsRoutes';
import { useOnError } from '../../providers/useOnError';

import { InvoiceDetails, UnableDownloadModal } from './ui/InvoiceDetails';

export interface InvoicesPageProps {
  useFetchInvoicesQuery: UseFetchInvoicesQuery;
  useFetchInvoiceQuery: UseFetchInvoiceQuery;
  useLazyFetchInvoicesQuery: UseLazyFetchInvoicesQuery;
}

const columnHelper = createColumnHelper<FetchInvoicesListItem>();

const feeTypesOptions = optionsWithAllOption(FEE_TYPE_OPTIONS);
const invoiceStatusOptions = optionsWithAllOption(INVOICE_STATUS_OPTIONS);

export const InvoicesPage: FC<InvoicesPageProps> = ({
  useFetchInvoicesQuery,
  useFetchInvoiceQuery,
  useLazyFetchInvoicesQuery
}) => {
  const t = useTranslate('shared-admin-ui.financial');
  const { getSettingsUrl } = useSettingsRoutes();
  const { app } = useApp();
  const onError = useOnError();

  const user = useSelector(getAuthSelector('user'));

  const location = useLocation();

  const [showUnableDownloadModal, setShowUnableDownloadModal] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState<FetchInvoicesListItem>();

  const { getTeachingInvoicesUrl, getInvoiceHistoryUrl, getPaymentHistoryUrl } = useSettingsRoutes();

  const columns = useMemo(() => ([
    columnHelper.accessor((originalRow) => formatCurrency(originalRow.amount, { currency: 'USD', cents: true }), {
      id: 'amount',
      header: t('invoiceAmount'),
      enableSorting: true
    }),
    columnHelper.accessor(originalRow => t(`paymentTypes.${originalRow.type}`), {
      id: 'type',
      header: t('transactionType'),
      enableSorting: false
    }),
    columnHelper.accessor((originalRow) => originalRow.type === 'ANNUAL_FEE'
      ? 'Acadeum'
      : originalRow.institution?.name, {
      id: 'counterparty',
      header: t('counterparty'),
      enableSorting: false,
      cell: ({ row }) => {
        return row.original.type === 'ANNUAL_FEE'
          ? t('acadeum')
          : row.original.institution ? <DataBlock type="institution" institution={row.original.institution}/> : null;
      }
    }),
    columnHelper.accessor('createdAt', {
      header: t('invoiceDate'),
      enableSorting: true,
      cell: ({ getValue }) => {
        return (
          <FormatDate
            utc={false}
            date={getValue()}
            month="short"
            day="numeric"
          />
        );
      }
    }),
    columnHelper.accessor('dueDate', {
      header: t('dueDate'),
      enableSorting: true,
      cell: ({ getValue }) => {
        const dueDate = getValue();
        return dueDate ? (
          <FormatDate
            utc={false}
            date={dueDate}
            month="short"
            day="numeric"
          />
        ) : (
          <Blank/>
        );
      }
    }),
    columnHelper.accessor('status', {
      header: t('invoiceStatus'),
      enableSorting: false,
      cell: ({ getValue }) => <Tag variant={getValue()}/>
    }),
    columnHelper.accessor('number', {
      header: t('invoiceNumber'),
      enableSorting: false,
      cell: ({ row }) => {
        return (
          <Link
            external
            removeUnderline={false}
            to={row.original.invoicePdfUrl}
            onClick={(event) => {
              if (!row.original.invoicePdfUrl) {
                event.preventDefault();
                setShowUnableDownloadModal(true);
                return;
              }
            }}
          >
            {row.original.number}
          </Link>
        );
      }
    }),
    columnHelper.accessor('id', {
      enableSorting: true,
      header: t('stripeInvoiceId')
    }),
    columnHelper.display({
      id: 'actions',
      cell: ({ row, downloadRow }) => (
        <Actions
          variant="kebab"
          actions={[
            {
              title: t('download'),
              onClick: () => downloadRow()
            },
            {
              title: t('seeInvoiceDetails'),
              onClick: () => setSelectedInvoice(row.original)
            },
            ...(row.original.status === 'paid' ? [
              {
                title: t('seePaymentDetails'),
                url: `${getPaymentHistoryUrl()}?search=${row.original.chargeId}`
              }
            ] : [])
          ]}
        />
      )
    })
  ]), [t]);

  const exportDataColumns = useMemo(() => columns.reduce<TableExportDataColumns<'xlsx', FetchInvoicesListItem>>((res,
    column) => {
    if (column.id === 'actions') {
      return res;
    }

    if (typeof column.header !== 'string') {
      throw new Error('Invalid column header');
    }

    const widthDic = {
      amount: 7,
      createdAt: 25,
      dueDate: 25
    };

    res.push({
      title: column.header,
      width: widthDic[column['accessorKey']] || 18,
      value: row => {
        if (typeof column['accessorKey'] === 'string') {
          return get(row, column['accessorKey']);
        } else if (typeof column['accessorFn'] === 'function') {
          return column['accessorFn'](row);
        }
        throw new Error(`Invalid column accessor: ${column.header}`);
      }
    });

    return res;
  }, []), [columns, t]);

  const {
    data,
    error,
    isFetching,
    _globalFilterOptions,
    _paginationOptions,
    _filtersOptions,
    _fetchDataForExport,
    _sortingOptions
  } = useQueryWithPagination(useFetchInvoicesQuery, {
    search: location.query.invoice as string,
    sort: [{ by: 'createdAt', asc: false }]
  }, useLazyFetchInvoicesQuery);

  const menuItems = useMemo(() => {
    const items: TabsNavigationProps['menuItems'] = [
      {
        title: t('enrollingInvoices'),
        url: getInvoiceHistoryUrl(),
        active: true
      }
    ];

    if (user?.institution.teaching) {
      items.push({
        title: t('teachingInvoices'),
        url: getTeachingInvoicesUrl()
      });
    }
    return items;
  }, [user?.institution.teaching, getInvoiceHistoryUrl, getTeachingInvoicesUrl]);

  if (error) {
    return onError(error);
  }

  return (
    <Page
      title={t('invoices')}
      breadcrumbs={[[t(app === 'admin' ? 'general' : 'settings', { global: true }), getSettingsUrl()], t('invoices')]}
    >
      <TabsNavigation menuItems={menuItems}/>

      <Filters
        border
        values={_filtersOptions.filters}
        onFiltersChange={_filtersOptions.onFiltersChange}
      >
        <Filters.Row>
          <Filters.Select
            name="type"
            label={t('type')}
            getFilterLabel={(value) => t(`paymentTypes.${value}`)}
            options={feeTypesOptions}
          />
          {_filtersOptions.filters.type !== 'ANNUAL_FEE' && (
            <Filters.Institution
              multiple
              type="institution"
              name="institutionIds"
              label="Counterparty"
            />
          )}
          <Filters.Select
            name="status"
            label="Status"
            options={invoiceStatusOptions}
          />
          <Filters.DateRange
            utc
            name="createdAt"
            label="Invoice Date"
          />
        </Filters.Row>
      </Filters>

      <Table
        enableRowSelection
        columns={columns}
        data={data?.results}
        loading={isFetching}
        sortingOptions={_sortingOptions}
        paginationOptions={_paginationOptions}
        globalFilterOptions={_globalFilterOptions}
        translate={{
          resultText: ({ totalCount }) => t('invoicePage.resultText', { totalCount }),
          selectedResultText: ({ totalCount, selectedRowsCount }) => t('invoicePage.selectedResultText', {
            totalCount,
            selectedRowsCount
          }),
          searchPlaceholder: t('invoicePage.searchByInvoiceId')
        }}
        exportOptions={{
          type: 'xlsx',
          fileName: 'enrolling_invoices',
          exportDataColumns,
          fetchDataForExport: _fetchDataForExport
        }}
      />

      {selectedInvoice && (
        <InvoiceDetails
          useFetchInvoiceQuery={useFetchInvoiceQuery}
          invoiceId={selectedInvoice.id}
          chargeId={selectedInvoice.chargeId}
          onHide={() => setSelectedInvoice(undefined)}
        />
      )}

      <UnableDownloadModal
        show={showUnableDownloadModal}
        onHide={() => setShowUnableDownloadModal(false)}
      />
    </Page>
  );
};
