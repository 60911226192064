import React, { useMemo } from 'react';

import type { OnSubmit } from '@acadeum/ui';
import { Form } from '@acadeum/ui';
import type { DeepPartial } from '@acadeum/types';

import type { FacultyCredentialsSectionValues } from './ui/FacultyCredentialsSection';
import { FacultyCredentialsSection } from './ui/FacultyCredentialsSection';
import type { CourseMaterialsSectionValues } from './ui/CourseMaterialsSection';
import { CourseMaterialsSection } from './ui/CourseMaterialsSection';
import type { AdditionalCostsSectionValues } from './ui/AdditionalCostsSection';
import { AdditionalCostsSection } from './ui/AdditionalCostsSection';
import type { CertificatesSectionValues } from './ui/CertificatesSection';
import { CertificatesSection } from './ui/CertificatesSection';
import type { DescriptionSectionValues } from './ui/DescriptionSection';
import { DescriptionSection } from './ui/DescriptionSection';
import type { CreateEnrollmentSectionValues } from './ui/EnrollmentSection';
import { EnrollmentSection } from './ui/EnrollmentSection';
import type { CreateScheduleSectionValues } from './ui/ScheduleSection';
import { ScheduleSection } from './ui/ScheduleSection';
import type { CreateAcademicSectionValues } from './ui/AcademicSection';
import { AcademicSection } from './ui/AcademicSection';
import type { CategorySectionValues } from './ui/CategorySection';
import { CategorySection } from './ui/CategorySection';
import type { GeneralSectionValues } from './ui/GeneralSection';
import { GeneralSection } from './ui/GeneralSection';
import type { NotesSectionValues } from './ui/NotesSection';
import { NotesSection } from './ui/NotesSection';
import { CourseFormFooter } from './ui/CourseFormFooter';
import { OnDemandDatesSection } from './ui/OnDemandDatesSection';

import { Mode } from './helpers';

export type AddCourseFormValues =
  GeneralSectionValues
  & DescriptionSectionValues
  & CreateAcademicSectionValues
  & CategorySectionValues
  & CreateEnrollmentSectionValues
  & CreateScheduleSectionValues
  & CourseMaterialsSectionValues
  & AdditionalCostsSectionValues
  & CertificatesSectionValues
  & FacultyCredentialsSectionValues
  & NotesSectionValues & {
  // Defined here to avoid TS errors because TS not merged { ... }[] into the type
  sections: {
    code: string;
    cost: number;
    schedule: string | null;
    startDate: Date;
    endDate: Date;
    lastAddDate: Date;
    lastDropDate: Date;
  }[];
}

export interface AddCourseFormProps {
  cancelUrl?: string;
  onBack?: () => void;
  onSubmit: OnSubmit<AddCourseFormValues>;
  defaultValues?: DeepPartial<AddCourseFormValues>;
  submitText: string;
  onDemandCourseDropDateDaysAfterStartDate?: number;
  onDemandCourseEndDateDaysAfterStartDate?: number;
}

export const AddCourseForm: React.FC<AddCourseFormProps> = ({
  cancelUrl,
  submitText,
  onBack,
  onSubmit,
  onDemandCourseDropDateDaysAfterStartDate,
  onDemandCourseEndDateDaysAfterStartDate,
  defaultValues: propsDefaultValues
}: AddCourseFormProps) => {
  const defaultValues = useMemo(() => ({
    ...propsDefaultValues,
    onDemand: Boolean(propsDefaultValues?.onDemand),
    additionalCosts: Boolean(propsDefaultValues?.additionalCosts),
    zeroTextbookCost: Boolean(propsDefaultValues?.zeroTextbookCost)
  }), []);

  return (
    <Form
      defaultValues={defaultValues}
      onSubmit={onSubmit}
    >
      <GeneralSection mode={Mode.CREATE} />
      <DescriptionSection />
      <AcademicSection mode={Mode.CREATE} />
      <OnDemandDatesSection
        onDemandCourseDropDateDaysAfterStartDate={onDemandCourseDropDateDaysAfterStartDate}
        onDemandCourseEndDateDaysAfterStartDate={onDemandCourseEndDateDaysAfterStartDate}
      />
      <CategorySection />
      <EnrollmentSection />
      <ScheduleSection mode={Mode.CREATE} />
      <CourseMaterialsSection />
      <AdditionalCostsSection />
      <CertificatesSection />
      <FacultyCredentialsSection />
      <NotesSection />

      <CourseFormFooter
        cancelUrl={cancelUrl}
        submitText={submitText}
        onBack={onBack}
        mode={Mode.CREATE}
      />
    </Form>
  );
};
