import React from 'react';
import { useController } from 'react-hook-form';

import { CheckIcon, PlusIcon } from '@acadeum/icons';

import { Chip } from '../../../Chip';
import { Icon } from '../../../Icon';

import styles from './FormChips.module.scss';
import { Text } from '../../../Text';

export const FormChips = ({
  id,
  label,
  name,
  defaultValue,
  rules,
  options,
  disabled
}) => {
  const { field } = useController({ name, rules, defaultValue, disabled });

  const { onChange, value, onBlur } = field;

  return (
    <div
      role="group"
      aria-labelledby={id}
      aria-label={label}
      className={styles.root}
    >
      {options.map((option, index) => {
        const checked = !!value?.includes(option.value);
        return (
          <Chip
            key={index}
            aria-pressed={checked}
            onBlur={onBlur}
            disabled={disabled}
            onClick={() => {
              if (!Array.isArray(value)) {
                onChange([option.value]);
              } else if (checked) {
                onChange(value.filter(value => value !== option.value));
              } else {
                onChange([...value, option.value]);
              }
            }}
          >
            <Text as="span" color={checked ? 'primary' : 'secondary'}>
              {option.label}
            </Text>
            <Icon
              ml="sm"
              color={checked ? 'accent' : 'secondary'}
              icon={checked ? CheckIcon : PlusIcon}
            />
          </Chip>
        );
      })}
    </div>
  );
};
